import { Accordion, Button, Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/navigation";
import { EffectFade, Navigation, Pagination } from 'swiper/modules';
import { useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import audioFile from '../assets/images/audi-1.mp3';
import audioFileMain from '../assets/images/bhavna-1726462787114.wav';
import audioFileMainOne from '../assets/images/audio-main.aac';
import audioFileMainTwo from '../assets/images/audio-main-3.aac';
import audioFileMainThree from '../assets/images/audio-main-2.aac';
import { ContactUs } from "./ContactUs";
import ReactAudioPlayer from "react-audio-player";

export const Home = () => {


  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 1);
  }, [])

  return (
    <>
      {/* first section start */}
      <section className="hero-section common-padding">
        <Container>
          <Row className="align-items-center">
            <Col lg={5}>
              <div className="how-work-content">
                {/* <h3>Sign up with US. </h3> */}
                {/* <h4>Lorem ipsum dolor consectetur.</h4> */}
                {/* <div className="works-box">
                  <div className="steps-box">
                    <span className="steps-iiner-round">
                      1
                    </span>
                    <span>
                      Integrate Anna into your business operations after the demo.
                    </span>
                  </div>
                  <div className="steps-box">
                    <span className="steps-iiner-round">
                      2
                    </span>
                    <span>
                      Leverage Anna’s advanced AI capabilities for better transcription, understanding, and customer communication.
                    </span>
                  </div>
                  <div className="steps-box">
                    <span className="steps-iiner-round">
                      3
                    </span>
                    <span>
                      Enhance customer satisfaction and streamline business processes with a superior AI-driven system.
                    </span>
                  </div>
                </div> */}
                <h1>AI Agents for
                  <span className="dark-text"> Utilities</span></h1>
                <h4>Support your customers and agents with Cognigy. AI, powered by Generative AI on voice and chat.</h4>
                <Button className="get-started-btn mt-4" onClick={() => navigate("/contact-us")}>
                  Get a demo <img src={require('../assets/images/arrow-icon-dark.svg').default} alt="logo" />
                </Button>
              </div>
            </Col>
            <Col lg={7}>
              <div className="how-work-img">
                <img src={require('../assets/images/ai_gif.gif')} alt="logo" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* first section end */}

      {/* phone & voice section start */}
      <section className="phone-voice-section">
        <Container>
          <Row className="align-items-center">
            <Col lg={4}>
              <div className="phone-img">
                <i className="fa-solid fa-phone-volume"></i>
                <h3>Phone & Voice</h3>
              </div>
            </Col>
            <Col lg={4}>
              <div className="phone-img">
                <i className="fa-solid fa-comments"></i>
                <h3>Chat & Messaging</h3>
              </div>
            </Col>
            <Col lg={4}>
              <div className="phone-img">
                <i className="fa-solid fa-headset"></i>
                <h3>Agent Copilot</h3>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* phone & voice section end */}

      {/* missed section starts */}
      <section className="missed-section" data-aos="fade-up"
      >
        <Container>
          <h4 className="upper-heading"><i className="fa-solid fa-newspaper"></i> Services</h4>
          <h2 className="main-heading text-center">
            Offer customers a seamless<br></br> self-service experience across voice
          </h2>
          {/* <p className="text-center">Keep your outbound teams a step ahead with tools like sentiment analysis, live Ai coaching, and real-time assists.</p> */}
          <Row className="mt-4 justify-content-center">
            <Col lg={4} md={6}>
              <div className="missed-card-box gray">

                <h3 className="mb-2">Understand Callers.</h3>
                <p> AI Office comprehends callers of all demographics, answering queries, and providing real-time responses without cumbersome phone menus.</p>
                <a href="#" className="read-more">Read More <i className="fa-solid fa-arrow-right"></i></a>
                <img src={require('../assets/images/missed-1.svg').default} className="mb-2" alt="logo" />
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="missed-card-box red">
                <h3 className="mb-2">Availability Scheduling.</h3>
                <p>Availability scheduling plays a pivotal role in systems dedicated to the effective management and optimization of resource and service utilization.</p>
                <a href="#" className="read-more">Read More <i className="fa-solid fa-arrow-right"></i></a>
                <img src={require('../assets/images/missed-3.svg').default} className="mb-2" alt="logo" />

              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="missed-card-box orange">
                <h3 className="mb-2">Manage Calls</h3>
                <p>Enable callers to swiftly book or modify reservations, keeping your staff informed without direct involvement.</p>
                <a href="#" className="read-more">Read More <i className="fa-solid fa-arrow-right"></i></a>
                <img src={require('../assets/images/missed-2.svg').default} className="mb-2" alt="logo" />

              </div>
            </Col>

          </Row>
        </Container>
      </section>
      {/* missed section ends */}

      {/* recordings sections start */}
      <section className="recordings-area">
        <Container>
          <h2 className="main-heading text-center">Voice Experiences Reimagined</h2>
          <Row>
            <Col md={4}>
              <div className="recording-area-inner active">
                <div className="recordings-area-left">
                  <img src={require('../assets/images/agent-kim-avatar.png')} />
                  <div className="recordings-area-left-content">
                    <h6>Kim </h6>
                    {/* <ul>
                      <li>English (US)</li>
                      <li>Soft</li>
                      <li>Airlines</li>
                    </ul> */}
                    <ReactAudioPlayer
                      className="recording-audio"
                      src={audioFile}
                      autoPlay
                      controls
                    />
                  </div>
                </div>
                {/* <div className="recordings-area-right">
                  <img src={require("../assets/images/audio-off.svg").default} alt="img" />
                  <img src={require("../assets/images/audio-on.svg").default} alt="img"/>
                 
                </div> */}
              </div>
            </Col>
            <Col md={4}>
              <div className="recording-area-inner">
                <div className="recordings-area-left">
                  <img src={require('../assets/images/avator-21.png')} />
                  <div className="recordings-area-left-content">
                    <h6>Dan </h6>
                    {/* <ul>
                      <li>English (US)</li>
                      <li>Soft</li>
                      <li>Airlines</li>
                    </ul> */}
                    <ReactAudioPlayer
                      className="recording-audio"
                      src={audioFileMain}
                      autoPlay
                      controls
                    />
                  </div>
                </div>
                {/* <div className="recordings-area-right">
                  <img src={require("../assets/images/audio-off.svg").default} alt="img" />
                  <img src={require("../assets/images/audio-on.svg").default} alt="img"/>
                  
                </div> */}
              </div>
            </Col>
            <Col md={4}>
              <div className="recording-area-inner">
                <div className="recordings-area-left">
                  <img src={require('../assets/images/agent-kim-avatar2.png')} />
                  <div className="recordings-area-left-content">
                    <h6>Ana</h6>
                    {/* <ul>
                      <li>English (US)</li>
                      <li>Soft</li>
                      <li>Airlines</li>
                    </ul> */}
                    <ReactAudioPlayer
                      className="recording-audio"
                      src={audioFileMainOne}
                      autoPlay
                      controls
                    />
                  </div>
                </div>
                {/* <div className="recordings-area-right">
                  <img src={require("../assets/images/audio-off.svg").default} alt="img" />
                  <img src={require("../assets/images/audio-on.svg").default} alt="img"/>
                 
                </div> */}
              </div>
            </Col>
            <Col md={4}>
              <div className="recording-area-inner">
                <div className="recordings-area-left">
                  <img src={require('../assets/images/avator-22.png')} />
                  <div className="recordings-area-left-content">
                    <h6>Chia </h6>
                    {/* <ul>
                      <li>English (US)</li>
                      <li>Soft</li>
                      <li>Airlines</li>
                    </ul> */}
                    <ReactAudioPlayer
                      className="recording-audio"
                      src={audioFileMainTwo}
                      autoPlay
                      controls
                    />
                  </div>
                </div>
                {/* <div className="recordings-area-right">
                  <img src={require("../assets/images/audio-off.svg").default} alt="img" />
                  <img src={require("../assets/images/audio-on.svg").default} alt="img"/>
                 
                </div> */}
              </div>
            </Col>
            <Col md={4}>
              <div className="recording-area-inner">
                <div className="recordings-area-left">
                  <img src={require('../assets/images/avator-23.png')} />
                  <div className="recordings-area-left-content">
                    <h6>Dave</h6>
                    {/* <ul>
                      <li>English (US)</li>
                      <li>Soft</li>
                      <li>Airlines</li>
                    </ul> */}
                    <ReactAudioPlayer
                      className="recording-audio"
                      src={audioFileMainThree}
                      autoPlay
                      controls
                    />
                  </div>
                </div>
                {/* <div className="recordings-area-right">
                  <img src={require("../assets/images/audio-off.svg").default} alt="img" />
                  <img src={require("../assets/images/audio-on.svg").default} alt="img"/>
                 
                </div> */}
              </div>
            </Col>
            <Col md={4}>
              <div className="recording-area-inner">
                <div className="recordings-area-left">
                  <img src={require('../assets/images/avator-24.png')} />
                  <div className="recordings-area-left-content">
                    <h6>Jenny</h6>
                    {/* <ul>
                      <li>English (US)</li>
                      <li>Soft</li>
                      <li>Airlines</li>
                    </ul> */}
                    <ReactAudioPlayer
                      className="recording-audio"
                      src={audioFile}
                      autoPlay
                      controls
                    />
                  </div>
                </div>
                {/* <div className="recordings-area-right">
                  <img src={require("../assets/images/audio-off.svg").default} alt="img" />
                  <img src={require("../assets/images/audio-on.svg").default} alt="img"/>
                
                </div> */}
              </div>
            </Col>
          </Row>

        </Container>
      </section>
      {/* recordings sections end */}

      {/* how works section starts */}
      <section className="how-works-section" data-aos="fade-up"
      >
        <Container>
          <h4 className="upper-heading"><i className="fa-brands fa-creative-commons-nd"></i> Working process</h4>
          <h2 className="main-heading text-center">
            How it works, in three easy steps.
          </h2>
          <Row className="align-items-center mt-4 ">
            <Col lg={12}>
              <Row>
                <Col lg={4}>
                  <div className="how-works-box">
                    <h2>Book demo </h2>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="how-works-box">
                    <h2>Get call within 5 seconds
                    </h2>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="how-works-box">
                    <h2>Switch your Business on AI Agent</h2>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      {/* how works section ends */}

      {/* testimonial section starts */}
      <section className="testimonial-section" data-aos="fade-up"
      >
        <Container>
          <h2 className="main-heading text-center">Trusted by 100+ businesses</h2>
          <p className="main-subheading text-center"> AiOffice gives you a superpower application that is remarkably better at transcribing, understanding, and speaking than traditional IVR systems.</p>
          <div className="outer-testimonial-box">
            <Swiper
              slidesPerView={3}
              spaceBetween={10}
              navigation={true}
              modules={[Navigation]}
              className="mySwiper"
              breakpoints={{
                320: {
                  slidesPerView: 1,
                },
                480: {
                  slidesPerView: 1,
                },
                575: {
                  slidesPerView: 1,
                },
                640: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 3,
                },
                1200: {
                  slidesPerView: 3,
                },
              }}
            >
              <SwiperSlide>
                <div className="client-box">
                  <div className="inner-client-box">
                    <img src={require('../assets/images/avatar.png')} alt="logo" />
                    <div>
                      <h5>Sarah</h5>
                      <p>CEO at Guidlor Corp</p>
                    </div>
                  </div>
                  <p className="mt-2">"Our virtual agent handles a workload equivalent to about 25 employees and plays a significant role in generating millions in sales."</p>
                  <img src={require('../assets/images/quote.svg').default} alt="logo" className="quote-box" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="client-box">
                  <div className="inner-client-box">
                    <img src={require('../assets/images/avatar-2.png')} alt="logo" />
                    <div>
                      <h5>Chia</h5>
                      <p>Operations Manager at Distrix Services</p>
                    </div>
                  </div>
                  <p className="mt-2">"We're constantly exploring new ways to serve our customers, and the ability to scale our virtual agent to better meet their needs has been key to its success."</p>
                  <img src={require('../assets/images/quote.svg').default} alt="logo" className="quote-box" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="client-box">
                  <div className="inner-client-box">
                    <img src={require('../assets/images/avatar-3 (2).png')} alt="logo" />
                    <div>
                      <h5>John</h5>
                      <p>Founder of Astra Solutions</p>
                    </div>
                  </div>
                  <p className="mt-2">"We're thrilled with the results so far. Providing 24/7 support to our customers has unlocked a whole new realm of opportunity." </p>
                  <img src={require('../assets/images/quote.svg').default} alt="logo" className="quote-box" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="client-box">
                  <div className="inner-client-box">
                    <img src={require('../assets/images/avatar.png')} alt="logo" />
                    <div>
                      <h5>Sarah</h5>
                      <p>CEO at XYZ Corp</p>
                    </div>
                  </div>
                  <p className="mt-2">"The AI Office has revolutionized our call handling. It's like having a reliable assistant 24/7."</p>
                  <img src={require('../assets/images/quote.svg').default} alt="logo" className="quote-box" />
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </Container>
      </section>
      {/* testimonial section ends */}

      {/* partner section start */}
      <section className="partner-section common-padding">
        <Container>
          <h2 className="main-heading text-center">
          Trusted by more than 1,000 brands worldwide
          </h2>
          <div className="partnet-box">
            <div className="under-partner">
              <img src={require("../assets/images/img-1.png")} alt="logo" />
            </div>
            <div className="under-partner">
              <img src={require("../assets/images/img-2.png")} alt="logo" />
            </div>
            <div className="under-partner">
              <img src={require("../assets/images/img-3.png")} alt="logo" />
            </div>
            <div className="under-partner">
              <img src={require("../assets/images/img-5.png")} alt="logo" />
            </div>
            <div className="under-partner">
              <img src={require("../assets/images/img-6.png")} alt="logo" />
            </div>
            <div className="under-partner">
              <img src={require("../assets/images/img-7.png")} alt="logo" />
            </div>
          </div>
        </Container>
      </section>
      {/* partner section end */}

      {/* customer-service-start */}
      <section className="customer-service">
        <Container>
          <div className="ready-box">
            <div className="customer-service-left">
              <img src={require("../assets/images/customer-service.png")} />
            </div>
            <div className="customer-service-right">
              <h3>Transform Customer Service for Tomorrow, Today</h3>
              <p>The choice is yours</p>

              <Button className="get-started-btn get-started-for-free" onClick={() => navigate("/contact-us")}>
                Hire an AI Agent  <img src={require('../assets/images/arrow-icon.svg').default} alt="logo" />
              </Button>
            </div>
          </div>
        </Container>
      </section>
      {/* customer-service-end */}

    </>
  );
};
