import { Col, Container, Row, Form, Button } from "react-bootstrap";
import { Loader } from "../common/Loader";
import { apiServices } from "../service/api.service"
import { useState } from "react";
import React, { useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import swal from "sweetalert";
export const ContactUs = () => {
  const EMAILREGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  const [isLoader, setisLoader] = useState(false)
  const [countrycode, setCountrycode] = useState("+1")
  const [customerDetail, setcustomerDetail] = useState({
    name: "",
    email: "",
    phone: "",
    company_name: "",
    // company_type: "",
    source: ""

  });
  useEffect(() => {
    window.scrollTo(0, 1);
  }, [])

  const [customerDetailErr, setcustomerDetailErr] = useState({
    first_name: "",
    last_name: "",
    email: "",
    company_name: "",
    // company_type: "",
    source: ""

  });
  function contactusValidation() {

    let formValidated = true;

    if (customerDetail.name === "") {

      formValidated = false;
      setcustomerDetailErr((obj) => {
        return {
          ...obj,

          first_name: "Please enter your name"
        };
      });
    } else if (customerDetail.email.trim() === "") {

      formValidated = false;
      setcustomerDetailErr((obj) => {
        return {
          ...obj,

          email: "Please enter email address",

        };
      });
    } else if (!EMAILREGEX.test(customerDetail.email)) {
      console.log(EMAILREGEX.test(customerDetail.email), "valid email field")
      formValidated = false;
      setcustomerDetailErr((obj) => {
        return {
          ...obj,
          first_name: "",

          phone: "",
          company_name: "",
          email: "Enter a valid email address",

        };
      });
    } else if (customerDetail.phone.trim() === "") {
      formValidated = false;
      setcustomerDetailErr((obj) => {
        return {
          ...obj,
          first_name: "",
          email: "",

          phone: "Enter your phone number"
        };
      });
    } else {
      formValidated = true;
      setcustomerDetailErr((obj) => {
        return {
          ...obj,

          first_name: "",
          email: "",
          phone: "",
          company_name: "",
          company_type: ""

        };
      });
    }
    return formValidated

  }

  function contactus() {
    let valid = contactusValidation()
    if (valid) {
      setcustomerDetailErr({
        first_name: "",
        last_name: "",
        email: "",
        company_name: "",
        // company_type: "",
        source: ""

      })
      setisLoader(true)
      // console.log("=======customerDetail====",customerDetail)
      // return
      let data = {
        name: customerDetail.name,
        email: customerDetail.email,
        phone: "+" + customerDetail.phone,
        company_name: customerDetail.company_name,
        // company_type: customerDetail.company_type,
        source: ""
      }
      //  console.log("=======customerDetail====",data)
      //  return
      apiServices.contact_us(data).then((res) => {
        setisLoader(false)
        swal({ icon: 'success', text: " Thank you for expressing your interest. You will receive a call shortly.", button: "OK" }).then(() => {
          setcustomerDetail({
            name: "",
            last_name: "",
            email: "",
            company_name: "",
            // company_type: "",
            source: ""

          })
        })


      })
        .catch((err) => {
          setisLoader(false)

        });
    }
  }



  return (



    <>
      {isLoader && <Loader />}



      {/* contact us section start */}
      <section className="contact-us-section" id="contact-us-section"> 

        <Container>
          <h5 className="upper-heading"> <i className="fa-solid fa-address-book"></i> CONTACT US</h5>
          <h2 className="main-heading text-center shimmer">See how AI Office works</h2>
          <Row className="justify-content-center mt-3">
            <Col lg={6} md={8}>
              <Form className="contact-form">
                <Form.Group className="mb-3" >
                  <Form.Label>Name *</Form.Label>
                  <Form.Control type="name" placeholder="Name" value={customerDetail.name} onChange={(e) => setcustomerDetail({
                    ...customerDetail,
                    name: e.target.value
                  })} />
                  <span className="error">{customerDetailErr.first_name}</span>
                </Form.Group>
                <Form.Group className="mb-3" >
                  <Form.Label>Work Email *</Form.Label>
                  <Form.Control type="email" value={customerDetail.email} placeholder="Work Email " onChange={(e) => setcustomerDetail({
                    ...customerDetail,
                    email: e.target.value
                  })} />
                  <span className="error">{customerDetailErr.email}</span>
                </Form.Group>
                <Form.Group className="mb-3 phone-number-text" >
                  <Form.Label>Phone Number *</Form.Label>
                  <PhoneInput
                    className="form-control"
                    country={'us'}
                    value={customerDetail.phone}
                    onChange={(e, country) => {
                      // setCountrycode(country.dialCode);
                      // console.log(e)
                      // console.log(country.dialCode)
                      setcustomerDetail({
                        ...customerDetail,
                        phone: e
                      })
                    }

                    }
                  />

                  <span className="error">{customerDetailErr.phone}</span>
                </Form.Group>
                {/* <Form.Group className="mb-3" >
                  <Form.Label>Company Name *</Form.Label>
                  <Form.Control type="name" value={customerDetail.company_name} placeholder="Name"  onChange={(e)=>setcustomerDetail({
                ...customerDetail,
                company_name:e.target.value  })} />
                <span className="error">{customerDetailErr.company_name}</span>
                </Form.Group> */}
                {/* <Form.Group className="mb-3" >
                  <Form.Label>Company Industry *</Form.Label>
                  <Form.Select aria-label="Default select example" value={customerDetail.company_type} onChange={(e)=>setcustomerDetail({
                ...customerDetail,
                company_type:e.target.value  })}>
                    <option>Please Select</option>
                    <option value="Restaurant">Restaurant</option>
                    <option value="Ecommerce">Ecommerce</option>
                    <option value="Retail">Retail</option>
                    <option value="Law Firms">Law Firms</option>
                    <option value="Healthcare">Healthcare</option>
                    <option value="Healthcare">Insurance Industry</option>
                  </Form.Select>
                  <span className="error">{customerDetailErr.company_type}</span>
                </Form.Group> */}
                <div className="text-center mt-5">
                  <Button className="get-started-btn" onClick={() => contactus()}>Request a Demo</Button>
                </div>
              </Form>
            </Col>
          </Row>
          {/* <Row className="mt-4 justify-content-center">
            <Col lg={4} md={6}>
              <div className="feature-box">
                <h4>Get 1:1 Demo</h4>
                <p>Finding it difficult to navigate our suite of products? Get on a call with our product experts for personalized demo</p>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="feature-box">
                <h4>Get 1:1 Demo</h4>
                <p>Finding it difficult to navigate our suite of products? Get on a call with our product experts for personalized demo</p>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="feature-box">
                <h4>Get 1:1 Demo</h4>
                <p>Finding it difficult to navigate our suite of products? Get on a call with our product experts for personalized demo</p>
              </div>
            </Col>
          </Row> */}
        </Container>
      </section>
      {/* contact us section ends */}

      {/* ready section starts */}
      <section className="ready-section">
        <Container>
          <div className="ready-box">
            <h2 className="main-heading mb-4">Ready to learn more ?</h2>
            <h5 className="mb-4">Spend 5 minutes to get a ChatGPT powered chatbot that works 24/7 to help you engage and retain more users and boost conversions.</h5>
            <a href="#contact-us-section">
            <Button className="get-started-btn">
              Get started for free
            </Button>
            </a>
          </div>
        </Container>
      </section>
      {/* ready section starts */}

    </>
  );
};
