import axios from "axios";

import { apiUrl } from "../config/config";


export const apiServices = {
    contact_us,
  
}




//    console.log('headers()....', headers());





async function contact_us(data) {
    return await axios.post(`${apiUrl}/outgoing/`, data);
}




 



















