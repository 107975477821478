import { Container, Nav, Navbar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export const Header = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className="top-header">
                <Container>
                    <p>&#128073; Transform every call into revenue with the world's most powerful Conversational AI </p>
                </Container>
            </div>
            <header>
                <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
                    <Container fluid className="side-padding">
                        <Navbar.Brand href="/" className="">
                            {/* <img src={require('../assets/images/logo.svg').default} alt="logo" /> */}
                            Your Logo
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav">
                        <img src={require('../assets/images/menu.svg').default} alt="logo" />
                        </Navbar.Toggle>
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="ms-auto">
                                <Nav.Link href="/">Home</Nav.Link>
                                <Nav.Link href="/#industry-serve">Industry We Serve</Nav.Link>
                                <Nav.Link onClick={() => navigate("/feature")} >Features</Nav.Link>
                                <Nav.Link onClick={() => navigate("/contact-us")} className="sign-in-btn">Book a demo
                                    <img src={require('../assets/images/arrow-icon.svg').default} alt="logo" />
                                </Nav.Link>
                                <Nav.Link href="tel:+1 (510) 298-3990" className="telephone-show"><i className="fa-solid fa-phone-volume me-2"></i> +1 (510) 298-3990
                                </Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </header>
        </>
    )
};