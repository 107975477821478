import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home } from "../component/Home";
import { CommonLayout } from "../common/CommonLayout";
import { ContactUs } from "../component/ContactUs";
import { Feature } from "../component/Feature";
import { TermsConditions } from "../component/TermsConditions";
import { PrivacyPolicy } from "../component/PrivacyPolicy";

export const Routing = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={<CommonLayout><Home /></CommonLayout>}
        ></Route>
        <Route
          path="/contact-us"
          element={<CommonLayout><ContactUs /></CommonLayout>}
        ></Route>
        <Route
          path="/feature"
          element={<CommonLayout><Feature /></CommonLayout>}
        ></Route>
         <Route
          path="/terms-condition"
          element={<CommonLayout><TermsConditions /></CommonLayout>}
        ></Route>
         <Route
          path="/privacy-policy"
          element={<CommonLayout><PrivacyPolicy /></CommonLayout>}
        ></Route>
      </Routes>
    </Router>
  );
};
